let categtory_title;

export class TreeNode {
  constructor(key, value = key, parent = null) {
    this.key = key;
    this.value = value;
    this.parent = parent;
    this.depth = 0; //depth of root is 0.
    this.children = [];
  }

  get isLeaf() {
    return this.children.length === 0;
  }

  get hasChildren() {
    return !this.isLeaf;
  }

  set setDepth(depth) {
    this.depth = depth;
  }
}

export default class Tree {
  // constructor(key, value = key) {
  //     this.root = new TreeNode(key, value);
  // }

  constructor(data_array, flag) {
    this.root = new TreeNode(1, 'root');
    this.flag = flag;
    /*
    0 means category tree in Home.vue
    1 means sku tree in Product.vue 
    */
    for (let node of data_array) {
      // console.log(node);
      if (node.parent_key == null) {
        this.insert(1, node._id, node);
      } else {
        this.insert(node.parent_key, node._id, node);
      }
    }
    this.setDepth();
  }

  // return the node object of the next leaf if it is found.
  // return null if not found.
  getNextLeafNode(leaf_id) {
    let node_list = [...this.postOrderTraversal()];
    let leaf_found = false;
    for (let i = 0; i < node_list.length; i++) {
      if (!leaf_found) {
        if (node_list[i].key === leaf_id) {
          leaf_found = true;
        }
      }
      {
        if (node_list[i].isLeaf) {
          return node_list[i];
        }
      }
    }
    return null;
  }

  *preOrderTraversal(node = this.root) {
    yield node;
    if (node.children.length) {
      for (let child of node.children) {
        yield* this.preOrderTraversal(child);
      }
    }
  }

  *postOrderTraversal(node = this.root) {
    if (node.children.length) {
      for (let child of node.children) {
        yield* this.postOrderTraversal(child);
      }
    }
    yield node;
  }

  /* dont include root */

  *getOneChain(node = this.root) {
    // this.root.children[0]
    if (node.children.length) {
      yield* this.getOneChain(node.children[0]);
    }
    yield node.key;
  }

  insert(parentNodeKey, key, value = key) {
    for (let node of this.preOrderTraversal()) {
      if (node.key === parentNodeKey) {
        let index = 0;
        if (value.is_disabled) {
          return true;
        }
        /* order by category weight*/
        if (this.flag === 1) {
          while (index < node.children.length) {
            if (node.children[index].value.category_weight >= value.category_weight) {
              index += 1;
            } else {
              break;
            }
          }
          const insert = (arr, index, newItem) => [
            ...arr.slice(0, index),
            newItem,
            ...arr.slice(index),
          ];
          node.children = insert(node.children, index, new TreeNode(key, value, node));
          return true;
        } else if (this.flag === 0 && value?.is_leaf) {
          /* order by price*/
          // econsole.log('sort by price');
          while (index < node.children.length) {
            // console.log('sort by price');
            // if (node.children[index]) break;
            // if (node.children[index].value) break;
            // if (node.children[index].value.sku_product) break;
            // if (node.children[index].value.sku_product.price) break;
            if (node.children[index].value.sku_product.price <= value.sku_product.price) {
              index += 1;
            } else {
              break;
            }
          }
          const insert = (arr, index, newItem) => [
            ...arr.slice(0, index),
            newItem,
            ...arr.slice(index),
          ];
          node.children = insert(node.children, index, new TreeNode(key, value, node));
          return true;
        }

        node.children.push(new TreeNode(key, value, node));
        return true;
      }
    }
    return false;
  }

  setDepth(node = this.root, depth = 0) {
    node.setDepth = depth;
    // console.log("depth:",depth)
    if (node.children.length) {
      depth += 1;
      for (let child of node.children) {
        this.setDepth(child, depth);
      }
    }
    return null;
  }

  remove(key) {
    for (let node of this.preOrderTraversal()) {
      const filtered = node.children.filter((c) => c.key !== key);
      if (filtered.length !== node.children.length) {
        node.children = filtered;
        return true;
      }
    }
    return false;
  }

  find(key) {
    for (let node of this.preOrderTraversal()) {
      if (node.key === key) return node;
    }
    return {};
  }
}

categtory_title = {};

export { categtory_title };
