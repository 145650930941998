<template>
  <v-container class="pa-0 grey lighten-4" style="max-width: 600px; margin: 0 auto">
    <v-skeleton-loader
      class="mx-auto"
      max-width="600"
      type="card-avatar, article, actions"
      v-if="loading"
    ></v-skeleton-loader>

    <div v-if="!loading" class="pa-0">
      <!-- TODO : multiple cover images -->
      <v-img class="ma-0 pa-0" :src="product.cover_images[0]" eager></v-img>

      <div class="">
        <!-- 商品価格 -->
        <v-card class="pl-3 py-2 mb-4 pb-2" elevation="0">
          <div class="ml-1">{{ product.brand }}</div>
          <h3 class="mb-2 ml-1" v-html="product.title" style="font-weight: bold"></h3>
          <v-card-text class="pa-0 px-1 text-body-2" v-html="product.subtitle"></v-card-text>
          <v-divider class="ml-1 mr-4 my-2"></v-divider>
          <div>
            <v-chip v-if="minimum_sku.value.choice_name" class="mr-2" x-small label>
              {{ minimum_sku.value.choice_name }}
            </v-chip>
          </div>
          <div class="mx-1 my-1 black--text">
            <div v-if="product.is_onsale" class="pt-2 text-body-1 deep-orange--text">
              <div class="font-weight-bold">
                <span class="text-decoration-line-through">{{ product.price }}</span>
                <span>{{ ` ${Math.floor(product.sale_price || 0)} ` }}</span>
                円
              </div>
              <div class="black--text text-caption" style="margin-top: -5px">
                税込
                <span class="text-decoration-line-through">
                  {{ Math.floor(product.price * (1 + product.tax)) }}
                </span>
                <span>{{ Math.floor(product.sale_price * (1 + product.tax)) }}</span>
                円
              </div>
            </div>
            <div v-else class="pt-2 text-body-1 deep-orange--text">
              <div class="font-weight-bold">{{ product.price }}円</div>
              <div class="black--text text-caption" style="margin-top: -5px">
                税込{{ Math.floor(product.price * (1 + product.tax)) }}円
              </div>
            </div>
          </div>
        </v-card>

        <v-card class="mt-2 pt-3 px-1 pb-1 mb-4" elevation="0">
          <v-card-text v-for="item in product.table" :key="item.field_name" class="my-0 py-0">
            <strong>{{ item.field_name }}</strong>
            {{ item.field_content }}
          </v-card-text>
          <v-card-text class="my-0 py-0">
            <strong>規格</strong>
            {{ sku_about }}
          </v-card-text>
          <v-card-text class="my-0 py-0">
            <strong>保存状態</strong>
            {{ preservation_category }}
          </v-card-text>
        </v-card>
        <v-card
          v-if="return_length(product.description) > 3"
          class="mt-2 pt-3 px-1 pb-1 mb-4 px-0"
          elevation="0"
        >
          <v-card-text class="u-pre-wrap" v-text="product.description"></v-card-text>
          <v-img
            class="ma-0 pa-0"
            v-for="item in product.body_images"
            :key="item"
            :src="item"
          ></v-img>
        </v-card>
        <v-card class="mt-2 pt-3 px-1 pb-1 mb-4" elevation="0">
          <v-card-text>
            写真の内容はイメージです。実際の商品とは異なる場合があります。写真のパッケージや食品表示等は撮影時と異なっている場合がありますので、実際の商品を必ずご確認ください。
          </v-card-text>
          <v-img src="@/assets/process.webp"></v-img>
        </v-card>

        <!-- REVIEW 機能 -->
        <template v-if="false">
          <v-card class="mx-auto" max-width="500">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Reviews</v-toolbar-title>
            </v-toolbar>
            <template>
              <v-text-field
                class="mx-4"
                v-model="reviewContent"
                v-on:keydown.enter="onReviewKeyDown"
              >
                <v-btn
                  slot="append"
                  class="mx-2 my-2"
                  fab
                  dark
                  small
                  color="success"
                  @click="send_review_clicker()"
                >
                  <v-icon dark>mdi-send</v-icon>
                </v-btn>
              </v-text-field>
              <!-- <v-file-input label="image" filled prepend-icon="mdi-camera"></v-file-input>
              <v-file-input label="video" filled prepend-icon="mdi-video"></v-file-input> -->
            </template>
            <v-list two-line>
              <v-list-item-group multiple>
                <template>
                  <v-list-item v-for="item in reviews" :key="item.text">
                    <template v-slot:default="{}">
                      <v-list-item-content>
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header v-text="item.text"></v-expansion-panel-header>
                            <!-- <v-expansion-panel-content
                              v-for="(reply, i) in item.reply_list"
                              :key="i"
                            >
                              {{ reply.text }}
                            </v-expansion-panel-content> -->
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>
                          <v-avatar color="blue-grey lighten-3" size="54">
                            <span class="white--text text-caption">
                              {{ item.user_name.slice(0, 4) }}
                            </span>
                          </v-avatar>
                        </v-list-item-action-text>

                        <!-- <v-icon v-if="!active" color="grey lighten-1">mdi-thumb-up-outline</v-icon>
                        <v-icon v-else color="yellow darken-3">mdi-thumb-up</v-icon> -->
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </template>

        <!-- 品切れERROR -->
        <!-- <div v-if="inventory_error">
          <v-card class="pa-4 mt-4 text-center" elevation="0">
            こちらの商品は好評につき、<br />
            <span style="font-weight: bold;">売り切れ終了</span
            >となりました。<br />
            入荷情報に関しては<br />こちらでお知らせします<br />

            <v-row class="justify-center my-2 mb-5">
              <v-btn
                href="https://lin.ee/Q0QoAIw"
                class="white--text"
                color="green accent-4"
                outlined
              >
                LINE公式アカウント
              </v-btn>
            </v-row>
          </v-card>
        </div> -->

        <!-- 商品情報 -->
        <!-- <v-card class=" mt-2 pt-3 px-0 pb-2 mb-4" elevation="0">
          <div>
            <span
              class="pl-2 ml-3 mb-2"
              style="border-left: solid 8px orange;display: block;height: 30px"
              ><span
                class="pt-1 font-weight-bold"
                style="display: inline-block;"
                >商品情報</span
              ></span
            >
          </div>

          <v-carousel
            v-model="image_slider"
            height="auto"
            hide-delimiters
            max-width="380"
          >
            <v-carousel-item v-for="n in product.images" :key="n" eager>
              <v-img class=" pa-0" :src="n" contain eager height="380"> </v-img>
            </v-carousel-item>
          </v-carousel>
          <div class="text-center">
            <v-chip x-small color="primary" class="ml-2" outlined
              >{{ image_slider + 1 }}/{{ product.images.length }}</v-chip
            >
          </div>
        </v-card> -->

        <!-- お客様の声 -->
        <!-- <v-card
          class=" mt-2 pt-3 px-1 pb-1 mb-4"
          elevation="0"
          v-if="product.reviews.length > 0"
        >
          <div>
            <span
              class="pl-2 ml-3"
              style="border-left: solid 8px orange;display: block;height: 30px"
              ><span
                class="pt-1 font-weight-bold"
                style="display: inline-block;"
                >お客様の声</span
              ></span
            >
          </div>-->

        <!-- <v-window
            v-model="review_slider"
            style="height: 170px;"
            class="text-caption mt-3 px-3"
          >
            <v-window-item v-for="item in product.reviews" :key="item" eager>
              <v-icon>mdi-format-quote-open</v-icon>
              <div v-html="item" class="text-caption px-3"></div>
              <div class="text-end">
                <v-icon>mdi-format-quote-close</v-icon>
              </div>
            </v-window-item>
          </v-window>
          <v-row class="justify-space-between mb-1">
            <v-btn text @click="prev_review">
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>

            <v-btn text @click="next_review">
              <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-card>  -->
      </div>

      <v-bottom-sheet v-model="sheet" scrollable>
        <v-sheet height="70vh">
          <div class="text-center pt-2">
            <v-row justify="end">
              <v-btn text @click="sheet = !sheet">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-row>
          </div>
          <v-card elevation="0">
            <!-- memo: overflow enables scrolling inside v-card -->
            <!-- memo: 50% dont work, but 50vh do. -->

            <!-- <div>{{ selected_sku_key }}</div>-->

            <!-- <div>{{sku_model.slice(1)}}</div> -->

            <div class="px-4 py-2" style="overflow: auto; height: calc(70vh - 170px)">
              <div v-for="sku_key in sku_model.slice(1)" :key="sku_key">
                <span class="subheading">{{ sku_tree.find(sku_key).value.field_name }}</span>
                <v-chip-group
                  mandatory
                  active-class="primary--text"
                  v-model="sku_model[sku_tree.find(sku_key).depth]"
                >
                  <v-chip
                    v-for="child in sku_tree.find(sku_model[sku_tree.find(sku_key).depth - 1])
                      .children"
                    :key="child.key"
                    :value="child.key"
                    @click="sku_clicked(sku_tree.find(child.key))"
                  >
                    {{ child.value.choice_name }}
                  </v-chip>
                </v-chip-group>

                <!-- <div v-if="sku_tree.find(sku_key).value.sku_product">
                  ¥{{ sku_tree.find(sku_key).value.sku_product.price }}
                </div> -->
              </div>
            </div>
          </v-card>

          <v-footer fixed class="py-3">
            <v-row class="mb-5">
              <v-col cols="8">
                <div v-if="product.is_onsale">
                  <div class="deep-orange--text text-body2">
                    <span class="text-decoration-line-through">
                      {{ selected_sku_info.value.sku_product.price }}
                    </span>
                    <span>
                      {{ ` ${selected_sku_info.value.sku_product.sale_price} 円` }}
                    </span>
                    <span class="ml-3 black--text">
                      (税込
                      {{
                        Math.floor(
                          selected_sku_info.value.sku_product.sale_price * (1 + product.tax),
                        )
                      }}円)
                    </span>
                  </div>
                </div>
                <div v-else>
                  <div class="deep-orange--text text-body2">
                    {{ selected_sku_info.value.sku_product.price }}円
                    <span class="ml-3 black--text">
                      (税込
                      {{
                        Math.floor(selected_sku_info.value.sku_product.price * (1 + product.tax))
                      }}円)
                    </span>
                  </div>
                </div>
              </v-col>
              <!-- <v-col cols="0"></v-col> -->
              <v-col cols="4" class="">
                <v-btn
                  icon
                  color="light-blue"
                  x-small
                  @click="() => handleMinus()"
                  :disabled="sku_number < 2"
                >
                  <v-icon>mdi-minus-circle-outline</v-icon>
                </v-btn>

                <span class="mx-2">
                  {{ sku_number }}
                </span>
                <v-btn icon color="light-blue" x-small @click="() => handlePlus()">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <!-- <v-card class="px-3">
              <v-list-item class="py-1">
                <v-list-item-content class=""> </v-list-item-content>

                <v-list-item-action>
                  <v-row no-gutters justify="end" align="end"> </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-card> -->

            <v-btn
              block
              rounded
              class="orange darken-2 white--text"
              elevation="0"
              :disabled="expired"
              @click="add_to_cart"
            >
              <div>確認</div>
            </v-btn>
          </v-footer>
        </v-sheet>
      </v-bottom-sheet>

      <v-dialog v-model="dialog">
        <!-- <v-btn>カートに追加しました</v-btn> -->
        <div class="message">
          <div class="content">カートに追加しました</div>
        </div>
      </v-dialog>

      <v-footer
        app
        class="pb-0 pt-0 px-0 white"
        style="max-width: 600px; margin: 0 auto"
        elevation="5"
      >
        <!--  expired -->
        <!-- <v-alert
          type="info"
          style="width:100%"
          v-if="expired"
          border="left"
          colored-border
          color="warning"
          elevation="0"
          class="ma-0 pb-1 pt-2 text-caption"
        >
          この商品の発売期間は終了いたしました
        </v-alert> -->
        <!-- product_title v-if="product.choices.length > 1" > -->
        <!-- <v-col class="pa-0 ma-0 text-end" cols="3">
            <div class="text-caption mt-3 font-weight-bold"> {{product.variation_name}}：</div>
          </v-col>
          <v-col class="pa-0 ma-0" cols="9">
            <v-chip-group v-model="category"  active-class="orange--text text--darken-2" style="display: inline;">
              <v-chip small
                v-for="n in product.choices"
                :key="n._id"
                :value = "n._id"
              >{{n.choice_name}}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row> -->

        <!-- <v-divider v-if="!expired"></v-divider> -->

        <!-- カートに入れる -->
        <v-row>
          <v-col cols="2" style="opacity: 0.8" @click="staff_chat">
            <div class="pl-3 pt-1 text-center">
              <v-icon>mdi-forum-outline</v-icon>
              <div style="font-size: 10px">質問</div>
            </div>
          </v-col>
          <v-col cols="3" style="opacity: 0.8" @click="goToCart">
            <div class="pl-3 pt-1 text-center">
              <v-icon>mdi-cart-outline</v-icon>
              <div style="font-size: 10px">カート</div>
            </div>
          </v-col>

          <v-col cols="7" class="text-center" style="font-weight: bold; opacity: 1">
            <v-btn color="orange darken-2" dark large @click="cart_button_clicker()">
              <v-icon>mdi-cart-outline</v-icon>
              カートに入れる
            </v-btn>
            <!-- expired -->
          </v-col>
        </v-row>

        <!-- 空白の行 -->
        <div class="pt-3">
          <v-row class="pt-0 pb-1 mb-0">
            <v-col cols="6" class="pa-0"></v-col>
          </v-row>
        </div>
        <!-- </v-container> -->
      </v-footer>
    </div>
  </v-container>
</template>

<script>
  // import { cartMethods } from '@/utils/methods.js';
  import Methods from '@/api/method.js';
  // import _ from 'lodash';
  import Tree from '@/api/GlobalData.js';
  import { contactURL } from '@/utils/const';

  export default {
    name: 'FeaturesProductsById',
    data: () => ({
      sheet: false, // show sheet if added to the scart
      reviewContent: '',
      image_slider: 0, //商品説明画像
      review_slider: 0, //口コミのslide

      expired: false, //シェア買いのリンクの期限が切れた
      inventory_error: false, //在庫切れ

      selected_sku: {},
      minimum_sku: {},

      sku_number: 1,
      product: {
        price: null,
        price_ori: null,
        text: '',
        reviews: [],
        sold: null,
        price_ref: null,
        needed: null,
        images: [],
        have_category: false,
        categories: [],
      },
      product_text: '',
      recieve_date: null,
      rating: 5,
      shared: 0,
      dialog1: false,
      dialog2: false,
      e1: 1,
      carousel: 0, //expansion panel
      length: 2, //length of carousel
      expansionModel: 0,
      delivery_schedule: null,

      category: 0,
      price: null,
      price_ori: null,
      text: '',
      reviews: [],
      sold: null,
      price_ref: null,
      needed: null,
      images: [],
      have_category: false,
      categories: [],

      loading: true,

      dialog: false,
      number: 1,

      /* sku tree*/
      sku_tree: null,
      sku_model: null, //a chain of nodes' key, directed to the SKU LEAF
      selected_sku_key: null,
    }),

    watch: {
      dialog(val) {
        if (!val) return;
        setTimeout(() => (this.dialog = false), 1000);
      },
    },

    async created() {
      // console.log(this.$route.params.product_id);
      let response = await Methods.getProduct(this.$route.params.product_id);

      this.product = response.data;
      let data = this.product.sku_objects;
      // console.log('product', this.product.preservation_category);
      // const tree = new Tree(1, 'root');

      const tree = new Tree(data, 0);

      // let print = [...tree.preOrderTraversal()];
      // console.log('print tree', print);

      let model = [...tree.getOneChain()].reverse();
      // console.log('model', model);

      this.sku_model = model;
      // this.selected_sku_key = model[model.length - 1];

      // this.minimum_sku = tree.find(this.selected_sku_key);

      let price = 100000;
      for (let sku of data) {
        if (sku.is_leaf == true) {
          if (sku.sku_product.price < price) {
            price = sku.sku_product.price;
            this.selected_sku_key = sku._id;
          }
        }
      }    
      this.product.price = price;
      this.minimum_sku = tree.find(this.selected_sku_key);
      if (this.product.is_onsale && this.product.sale_discount) {
        this.product.sale_price = price * this.product.sale_discount;
      }

      this.sku_tree = tree;
      // console.log(this.sku_tree);
      // console.log(this.sku_model.slice(1));

      // console.log('created');

      const reviewData = await Methods.getReviews({
        product_id: this.$route.params.product_id,
        page: 0,
        perPage: 1000,
      });
      this.reviews = reviewData.data.reviews;

      /* お届け予定日 */

      // this.recieve_date = response.data.delivery_date;
      // let temp_date = new Date(response.data.date);
      // temp_date = new Date(temp_date.setDate(temp_date.getDate() + response.data.delivery_days_expected));
      // let temp_month = Number(temp_date.getMonth())+1;
      // this.recieve_date = temp_month +"月"+temp_date.getDate()+"日";

      this.loading = false;
      // this.price = this.product.choices[0].price;
    },
    computed: {
      selected_sku_info: function () {
        let sku_info = this.sku_tree.find(this.selected_sku_key);
        if (this.product.is_onsale) {
          sku_info.value.sku_product.sale_price = Math.floor(
            (sku_info.value.sku_product.price || 0) * this.product.sale_discount,
          );
        }
        return sku_info;
        // for (let sku of this.product.skus) {
        //   // console.log(sku.sku_fields)
        //   // memo: cannot compare objects with "==" , it only compares the pointer, not the content of the objects.
        //   if (_.isEqual(sku.sku_fields, this.selected_sku)) {
        //     return sku;
        //   }
        // }
        // return null;
      },
      sku_about: function () {
        let print = [...this.sku_tree.preOrderTraversal()];
        if (print.length > 2) {
          return 'カートに追加時にお選びいただけます。';
        } else {
          return this.minimum_sku.value.choice_name;
        }
      },
      preservation_category: function () {
        let val = this.product.preservation_category;
        if (val == 0 || val == 3) {
          return '常温';
        } else if (val == 1) {
          return '冷蔵';
        } else if (val == 2) {
          return '冷凍';
        } else {
          return '';
        }
      },
    },
    methods: {
      return_length(str) {
        return str.length;
      },
      cart_button_clicker() {
        if (this.product.sku_objects.length > 1) {
          this.sheet = !this.sheet;
        } else {
          this.add_to_cart();
        }
      },
      show_sku_tag: function (choice) {
        if (choice.length > 1) {
          return true;
        }
        return false;
      },
      goToCart() {
        this.$router.push('/cart');
      },
      sku_clicked: function (node) {
        // if("sku_product" in node.value){
        //   /* update sku_key */
        //   console.log(node.key)
        //   this.selected_sku_key = node.key
        // }
        // let back_part=[...this.sku_tree.getOneChain(this.sku_tree.find(node.key))].reverse()
        // let head_part=this.sku_model.slice(0,node.depth)
        // let model =head_part.concat(back_part)
        // this.sku_model = model
        // this.selected_sku_key= model[model.length-1]

        let that = this;

        if ('sku_product' in node.value) {
          /* update sku_key */
          that.selected_sku_key = node.key;
        }
        /* update sku_model */
        let back_part = [...that.sku_tree.getOneChain(that.sku_tree.find(node.key))].reverse();
        let head_part = that.sku_model.slice(0, node.depth);
        let model = head_part.concat(back_part);
        this.sku_model = model;
        this.selected_sku_key = model[model.length - 1];
        this.selected_sku = this.sku_tree.find(this.selected_sku_key);

        // let print = tree.preOrderTraversal()
      },

      add_to_cart: function () {
        this.dialog = true;

        // console.log(this.sku_model);
        let sku_fields = [];
        for (let node_key of this.sku_model.slice(1)) {
          // type error : circular object => recursive function in class
          sku_fields.push(this.sku_tree.find(node_key).value);
        }

        // console.log(sku_fields);

        const sku_price_temp = this.selected_sku_info.value.sku_product.price || 0;

        let add_cart = {
          product_id: this.product._id,
          sku_id: this.selected_sku_key,
          is_onsale: this.product.is_onsale,
          is_alcohol: this.product.is_alcohol,
          sku_price: sku_price_temp, //単価
          sku_sale_price: Math.floor(sku_price_temp * this.product.sale_discount), //単価
          sku_number: this.sku_number, //個数
          sku_fields: sku_fields,
          lead_time: this.product.lead_time,
          product_fields: {
            title: this.product.title,
            subtitle: this.product.subtitle,
            description: this.product.description,
            standard: this.product.standard,
            table: this.product.table,
            thumbnail: this.product.thumbnail,
            cover_images: this.product.cover_images,
            body_images: this.product.body_images,
            price: this.product.price,
            tax: this.product.tax,
            sku_objects: this.product.sku_objects,
          },

          //productの性質(fields)を記録する
        };

        console.log('add_cart', add_cart);
        // this.$store.commit('cart/setMyCart', []);
        this.$store.commit('cart/addToCart', add_cart);

        // this.$store.commit('add_to_cart', {
        //   product_id: this.product._id,
        //   product_title: this.product.title,
        //   price: this.product.price,
        // });
        this.sheet = false;

        // if(this.$store.state.logged_in){
        //   this.$router.push('/pay');
        // }else{
        //   this.$router.push('/login');
        // }
        // } else {
        //   this.inventory_error = true;
        // }
      },
      back_to_home: function () {
        this.$router.push('/');
      },
      getFields: function (data) {
        if (data.value === 'root') {
          return [];
        }
        return [...this.getFields(data.parent), data.value];
      },
      // sku number
      handlePlus() {
        this.sku_number += 1;
      },
      handleMinus() {
        this.sku_number -= 1;
      },
      staff_chat: function () {
        window.location.href = contactURL;
      },
      async send_review_clicker() {
        await Methods.sendReview({
          user_id: this.$store.state.user.user_id,
          product_id: this.product._id,
          text: this.reviewContent,
        });
        const reviewData = await Methods.getReviews({
          product_id: this.$route.params.product_id,
          page: 0,
          perPage: 1000,
        });
        this.reviews = reviewData.data.reviews;
        this.reviewContent = '';
      },

      onReviewKeyDown: function () {
        this.send_review_clicker();
      },
      // async getReviews() {
      //   try {
      //     console.log(this.$route.params.product_id);

      //     let { data } = await Methods.getReviews({
      //       prodcut_id: this.$route.params.product_id,
      //       page: 0,
      //       perPage: 1000,
      //     });
      //     console.log(data);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    },
  };
</script>

<style scoped>
  * {
    margin: 0;
  }

  #Footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    /*background-color: red;*/
  }

  .message {
    display: flex;
    justify-content: center;
    position: fixed;
    /* bottom: 15%; */
    left: 0;
    width: 100vw;
  }

  .message .content {
    padding: 4px;
    background-color: #272727;
    border-radius: 4px;
    font-size: 14px;
    color: white;
  }
  .u-pre-wrap {
    white-space: pre-wrap;
  }
  s .mincho {
    font-family: 'ヒラギノ明朝 ProN W6', 'HiraMinProN-W6', 'HG明朝E', 'ＭＳ Ｐ明朝', 'MS PMincho',
      'MS 明朝', serif;
  }
</style>
