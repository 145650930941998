export const ORDER_STATUS = [
  {
    value: 0,
    label: '未払い',
    icon: 'mdi-gesture-tap-button',
    color: 'warning',
    'text-color': 'white',
  },
  {
    value: 1,
    label: '準備中',
    icon: 'mdi-gesture-tap-button',
    color: 'darkgray',
    'text-color': 'dimgrey',
  },
  {
    value: 2,
    label: '配送待ち',
    icon: 'mdi-gesture-tap-button',
    color: 'darkgray',
    'text-color': 'dimgrey',
  },
  {
    value: 3,
    label: '配送中',
    icon: 'mdi-gesture-tap-button',
    color: 'darkgray',
    'text-color': 'dimgrey',
  },
  {
    value: 4,
    label: '受取待ち',
    icon: 'mdi-gesture-tap-button',
    color: 'primary',
    'text-color': 'white',
  },
  {
    value: 5,
    label: '受取済',
    icon: 'mdi-gesture-tap-button',
    color: 'teal',
    'text-color': 'white',
  },
  {
    value: 10,
    label: 'キャンセル',
    icon: 'mdi-gesture-tap-button',
    color: 'white',
    'text-color': 'grey',
  },
  {
    value: 11,
    label: '回収待ち',
    icon: 'mdi-gesture-tap-button',
    color: 'darkgray',
    'text-color': 'dimgrey',
  },
];

// お問い合わせボタン
export const contactURL =
  'https://line.me/R/oaMessage/@532dhaol/%E3%80%90%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%E3%82%92%E3%81%94%E8%A8%98%E5%85%A5%E3%81%AE%E4%B8%8A%E3%80%81%E9%80%81%E4%BF%A1%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82%E3%82%B9%E3%82%BF%E3%83%83%E3%83%95%E3%81%8C24%E6%99%82%E9%96%93%E4%BB%A5%E5%86%85%E3%81%AB%E8%BF%94%E4%BF%A1%E8%87%B4%E3%81%97%E3%81%BE%E3%81%99%28%5E_%5E%29%E3%80%91';

export const WEEK_CHAR = {
  0: '日',
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
};
